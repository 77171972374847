<template>
  <b-modal :id="modalId" :ref="modalId" hide-header hide-footer @hidden="closeModal">
      <h2>Edit School Contact</h2>
      <hr/>
      <b-row>
        <b-col cols="12" lg="6">
          <BaseTextInput v-model="school.contact.lastName"
                    :v="$v.school.contact.lastName"
                    slug="lastName"
                    label="Contact Last Name"/>
        </b-col>
        <b-col cols="12" lg="6">
          <BaseTextInput v-model="school.contact.firstName"
                    :v="$v.school.contact.firstName"
                    slug="firstName"
                    label="Contact First Name"/>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="6">
          <BaseMaskedTextInput v-model="phone"
                    :v="$v.phone"
                    slug="phone"
                    mask="(###)###-####"
                    label="Phone Number"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="12">
          <BaseTextInput v-model="school.contact.email"
                    :v="$v.school.contact.email"
                    slug="emailAddress"
                    label="Email Address"/>
        </b-col>
      </b-row>
      <b-row v-if="school.classification === '0'">
          <b-col cols="12" lg="12">
              <BaseTextInput v-model="school.contact.ccEmail"
                             :v="$v.school.contact.ccEmail"
                             slug="emailAddress2"
                             label="Secondary Email Address"/>
          </b-col>
      </b-row>

      <b-button @click="closeModal" style="margin-right:15px;">Cancel</b-button>
      <b-button @click="submit" variant="primary" :disabled="$v.school.$invalid">Submit</b-button>
  </b-modal>

</template>

<script>

import axios from 'axios'
import { required, email, minLength, maxLength, numeric, requiredIf } from 'vuelidate/lib/validators'

export default {

  data: () => ({
    school: {
      contact: {
        areaCode: null,
        phonePrefix: null,
        phoneLine: null
      }
    },
    phone: null,
    email: null,
    ccEmail: null
  }),

  props: {
    schoolId: Number,
    showModal: {
      type: Boolean,
      default: false
    }
  },

  validations: {
    phone: {required, maxLength: maxLength(100),
      isValid(tel) {
        if (tel) {
          var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
          return phoneRegex.test(tel)
        } else {
          return true
        }
      } 
    },
    school: { 
      contact: {
        lastName: {required, maxLength: maxLength(100) },
        firstName: {required, maxLength: maxLength(100) },
        email: {required, email, maxLength: maxLength(100)},
        ccEmail: {required: false, email, maxLength: maxLength(100)}
      }
    }
  },

  watch: {
    showModal(newValue, oldValue) {
      if (newValue === true) {
        this.loadSchool()
      }
    },

    phone(newValue, oldValue) {
      //parse the phone number here.
      this.school.contact.areaCode = null
      this.school.contact.phonePrefix = null
      this.school.contact.phoneLine = null
      let numericsOnly = newValue.replace(/\D/g,'')
      if (numericsOnly && numericsOnly.length == 10) {
        this.school.contact.areaCode = numericsOnly.substr(0, 3)
        this.school.contact.phonePrefix = numericsOnly.substr(3, 3)
        this.school.contact.phoneLine = numericsOnly.substr(6, 4) 
      }
    }
  },

  mounted() {
  },
  
  computed: {
    modalId: function() {
      return "modal-school-contact"
    }
  },

  methods: {
    loadSchool() {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/school/${this.schoolId}`)
          .then(response => { 
              this.school = response.data
              this.phone = this.school.contact.areaCode + this.school.contact.phonePrefix + this.school.contact.phoneLine
              this.openModal()
          });
    },

    submit() {
      axios.put(`${process.env.VUE_APP_API_BASE_URL}/school/contact`, this.school.contact)
          .then(response => { 
              this.closeModal()
          });
    },

    openModal() {
      this.$bvModal.show(this.modalId)
    },

    closeModal() {
      this.$bvModal.hide(this.modalId)
      this.$nextTick(() => { this.$v.$reset() })
      this.$emit('closeModal')
    }

  },

}
</script>

<style>
</style>
