import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import Index from '@/views/Index.vue'
import LocalStorageService from "@/common/LocalStorageService";

const localStorageService = LocalStorageService.getService();

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      meta: {
        requiresAuth: false
      },
      component: Index
    },
    {
      path: '/report/:schoolId',
      name: 'report',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "report" */ './views/Report.vue')
    },

    {
      path: '/bulk/:schoolId',
      name: 'bulk',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "bulk" */ './views/Bulk.vue')
    },

    {
      path: '/report/rates/:reportId',
      name: 'report-rates',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "report-rates" */ './views/ReportRates.vue')
    },

    {
      path: '/requestAccess',
      name: 'requestAccess',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "timeout" */ './views/RequestAccess.vue')
    },
    {
      path: '/requestBulkAccess',
      name: 'requestBulkAccess',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "timeout" */ './views/RequestBulkAccess.vue')
    },
    {
      path: '/home',
      name: 'home',
      meta: {
        requiresAuth: true
      },
      component: Home
    },
    {
      path: '/timeout',
      name: 'timeout',
      meta: {
        requiresAuth: false
      },
      component: () => import(/* webpackChunkName: "timeout" */ './views/Timeout.vue')
    },

    {
      path: "/thanks",
      name: 'thanks',
      meta: {
        requiresAuth: false,
      },
      component: () => import(/* webpackChunkName: "thanks" */ './views/Thanks.vue')
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      meta: {
        requiresAuth: false
      },
      component: () => import(/* webpackChunkName: "unauthorized" */ './views/Unauthorized.vue')
    },

    {
      path: '/admin/users',
          name: 'users',
        meta: {
      requiresAuth: true,
      // authRoles: [ 'mdh-admin' ]
    },
      component: () => import(/* webpackChunkName: "users" */ './views/admin/Users.vue')
    },

    {
      path: '/admin/districts',
          name: 'districts',
        meta: {
      requiresAuth: true,
      //TODO authRoles: [  ]
    },
      component: () => import(/* webpackChunkName: "districts" */ './views/admin/DistrictList.vue')
    },

    {
      path: '/admin/organizations',
      name: 'organizations',
      props: true,
      meta: {
        requiresAuth: true,
      //TODO authRoles: [  ]
      },
      component: () => import(/* webpackChunkName: "organizations" */ './views/admin/Organizations.vue')
    },

    {
      path: '/admin/welcome',
          name: 'welcome',
        meta: {
      requiresAuth: true,
      //TODO authRoles: [  ]
    },
      component: () => import(/* webpackChunkName: "welcome" */ './views/admin/Welcome.vue')
    },

    {
      path: '/admin/year',
          name: 'year',
        meta: {
      requiresAuth: true,
      //TODO authRoles: [  ]
    },
      component: () => import(/* webpackChunkName: "year" */ './views/admin/CurrentYear.vue')
    },

    {
      path: '*', 
      component: Home
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (router.app.$keycloak && router.app.$keycloak.authenticated) {
      if (to.meta.authRoles) {
        let hasRole = false
        for (var i = 0; i < to.meta.authRoles.length; i++) {
          if (router.app.$keycloak.hasResourceRole(to.meta.authRoles[i])) {
            hasRole = true;
          }
        }
        if (hasRole) {
          next()
        } else {
          router.push('unauthorized') 
        }
      } else {
        next();
      }
    } else {
      const url = localStorageService.getLoginUrl()
      window.location.replace(url)
    }
  } else {
    next()
  }
})

let announcer = document.getElementById('announcer');
router.afterEach(function(to) {
  if (!to.name) { return; }
  announcer.innerHTML = `${to.name} page has loaded`;
});

export default router