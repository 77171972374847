<template>
  <div id="app">
    <header>
      <div id="nav">
        <b-navbar toggleable="lg" type="light" variant="mdh">
          <b-navbar-brand>
            <img src="./assets/logo-reverse.png" style="height:38px;" alt="Minnesota Department of Health Logo"/>
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item href="/home">Home</b-nav-item>              
               <b-nav-item-dropdown id="admin" v-if="authenticated && hasRole('mdh-admin')" text="Admin">
                <b-dropdown-item href="/admin/organizations">Organizations</b-dropdown-item>
                <b-dropdown-item href="/admin/users">Users</b-dropdown-item>
                <b-dropdown-item href="/admin/welcome">Welcome Message</b-dropdown-item>
                <b-dropdown-item href="/admin/year">School Year</b-dropdown-item>
               </b-nav-item-dropdown>
               <b-nav-item href="https://www.health.state.mn.us/people/immunize/ed/aisruserguide.pdf" target="_pdf">School Instructions (PDF)</b-nav-item>
               <b-nav-item href="https://www.health.state.mn.us/people/immunize/ed/ccaisrguide.pdf" target="_pdf">Child Care Instructions (PDF)</b-nav-item>
              <b-nav-item @click="logout" v-if="authenticated">Log out</b-nav-item>            
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </header>

    <main id="main-content">
      <b-container fluid>
              <router-view/>
      </b-container>
    </main>

    <footer>
      <div class="mt-5">
        <hr/>
        <div class="fluid-container footer justify-content-center">
          <p class="text-center" style="font-size:.85em;">
            <span  v-if="(authenticated && !hasRole('mdh-staff') && !hasRole('mdh-admin') && !hasRole('ie-staff')) ||!authenticated">
              For questions, please email us at <a href='mailto:health.aisr@state.mn.us'>health.aisr@state.mn.us</a> or call Minnesota Department of Health Immunization Program at 1-800-657-3970 (toll free) or 651-201-5503 (Twin Cities area) -
            </span>
            <span>v{{appVersion}}</span>
          </p>
        </div>
      </div>
    </footer>

  </div>
</template>

<script>
  import LocalStorageService from '@/common/LocalStorageService';
  import {version} from '../package.json';

  export default {
    metaInfo: {
      title: 'Welcome',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    },
    computed: {
      authenticated: function() {
        if (this.$keycloak) {
          return this.$keycloak.authenticated;
        } else {
          return false;
        }
      },
      appVersion: function() {
        return version;
        }
    },


    methods: {
      login () {
        const localStorageService = LocalStorageService.getService();
        localStorageService.clearAuthTokens();    
        const loginUrl = this.$keycloak.createLoginUrl()
        window.location.replace(loginUrl)  
      },
      logout() {
        const localStorageService = LocalStorageService.getService()
        localStorageService.clearAuthTokens()
        this.$store.dispatch('resetState')
        this.$keycloak.clearToken()
        this.$keycloak.logoutFn()
      },
      hasRole(roles) {
        if (this.$keycloak) {
          return this.$keycloak.hasResourceRole(roles)
        } else {
          return false;
        }
      }
    },
  }
</script>

<style lang="scss">

  @import "assets/_custom.scss";
  @import "vue-select/src/scss/vue-select.scss";
  @import "~bootstrap/scss/bootstrap.scss";
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
#app {
  font-family: 'Roboto', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

h1, .h1 {
  font-size: 30px;
  font-weight: 600;
}

h2, .h2 {
  font-size: 24px;
  font-weight: 600;
}

h3, .h3 {
  font-size: 18px;
  font-weight: 600;
}

button {
  margin-right: 18px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.bg-mdh {
  background-color: rgb(0, 56, 101);
}

#nav {
  padding-bottom: 30px;
}

#nav a {
  font-weight: normal;
  color: white;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#nav  a.dropdown-item {
  color: black !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.colWidth40 {
  width: 40%;
}

.is-invalid .d-block {
    color: #ef0000;
}

.label-required::after {
  color: #0a8927;
  font-size: .7em;
  text-transform: uppercase;
  margin-left: 19px;
  content:" required";

}

.wizard-card-footer div span {
  outline: none;
}

.wizard-card-footer div span:focus button {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(155, 89, 182, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(155, 89, 182, 0.25);
}

.wizard-nav li:has(.disabled) {
  user-focus: none;
 }

 .card {
   margin-bottom: 2em;
 }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  .navbar-light .navbar-toggler {
    border-color: white;
  }

  .sr-caption caption {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .sr-caption caption:active,
  .sr-caption caption:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }

  .nav-tabs {
      border-bottom: 2px solid #dee2e6;
      margin-top: 12px;
      margin-bottom: 5px;
  }

  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: aliceblue;
    border-color: #ffc107 #dee2e6 #fff;
    font-weight: 700;
    border-top-width: 4px;
  }

</style>
