<template>

    <form-group :validator="v" :label="label" :label-for="id" :description="tip"
        :messages="messages">
        <template slot-scope="{ attrs, listeners }">
            <b-form-input :id="id" v-model="text" type="date"
                v-bind="attrs"
                :state="v.$dirty ? !v.$error : null"
                v-on="listeners"
                :data-test="slug"
                :readonly="plaintext"
                :required="!v.required">
            </b-form-input>
        </template>
    </form-group>
    
</template>

<script>
import _ from "lodash"

export default {

    props: {
        value: [String, Number],
        v: [Object],
        label: [String],
        tip: [String],
        slug: [String],
        plaintext: Boolean,
        messages: [Object]
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(value) {
                this.v.$touch();
                this.$emit("input", value);
            }
        },
        id() {
            return _.uniqueId('text')
        },  
    }
}
</script>
<style>
.form-group > label {
    font-weight: 750;
}
</style>