<template>
    <div class="home">
        <b-container>
            <h1>{{appTitle}}</h1>
            <div style="margin-top:25px; margin-bottom:26px;">
            
                <span v-html="welcomeText"></span>

            </div>

            <b-row>
                <b-col>
                    <b-button variant="primary" @click="login" role="button">Login or Create Account</b-button>
                </b-col>
            </b-row>

            <b-row style="margin-top:30px;" class="text-secondary">
                <b-col>
                    <h2>Enrollment Process:</h2>
                    <ol>
                        <li>Create an account using the button above.</li>
                        <li>Fill out the 'Request School Access' form.</li>
                        <li>Your access request will be reviewed by MDH staff and you will be informed of review outcome.</li>
                    </ol>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import axios from "axios"
    import axiosRetry from 'axios-retry'

    axiosRetry(axios, { retries: 3,
        retryDelay: axiosRetry.exponentialDelay  })

    export default {
        data: () => ({
            appTitle: 'Annual Immunization Status Report',
            welcomeText: ''
        }),
        created() {
            document.title = "Welcome - AISR"
            this.getCurrentSchoolyear()
            this.getWelcomeText()
        },
        methods: {
            login () {
               window.location.replace('/home')
            },

            getWelcomeText() {
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/welcome`, { ignoreHeader: true })
                .then(response => { 
                    this.welcomeText = response.data
                });
            },

            getCurrentSchoolyear() {
                axios.get(`${process.env.VUE_APP_API_BASE_URL}/year/current`, { ignoreHeader: true })
                .then(response => { 
                    this.appTitle = 'Annual Immunization Status Report (' + response.data.longLabel + ')'
                });
            }
        }
    }
</script>

<style>
li {
    display: list-item !important;
}
</style>