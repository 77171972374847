<template>
    <div class="home">
            <h1 class="mb-2">{{appTitle}}</h1>           
            <span class="h2 mb-4 text-secondary" style="display: inline-block;">{{greeting}} </span> 

            <b-row class="mb-4">
              <b-col cols="6">
                <p>If you do not see a school or organization that you need access to, click on the Request School Access button and fill out the request form.</p>
                <b-button type="button" href="/requestAccess" variant="primary">Request School Access</b-button>
              </b-col>
                <b-col cols="6">
                    <p>If you require bulk query access to a school you are already approved to view, click on the Request Bulk Query Access button and
                        fill out the request form.</p>
                    <b-button type="button" href="/requestBulkAccess" variant="primary">Request Bulk Query Access</b-button>
                </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <div>
                  <b-tabs content-class="mt-3">
                    <b-tab title="Public/Charter Schools" active>
                      <SchoolList schoolType="public"
                        v-on:edit-school-contact="editSchoolContact($event)"/>
                    </b-tab>
                    <b-tab title="Private Schools">
                      <SchoolList schoolType="private"
                        v-on:edit-school-contact="editSchoolContact($event)"/>
                    </b-tab>
                    <b-tab title="Child Cares">
                      <SchoolList schoolType="ccc"
                        v-on:edit-school-contact="editSchoolContact($event)"/>
                    </b-tab>
                    <b-tab title="Higher Education">
                      <SchoolList schoolType="hied"
                        v-on:edit-school-contact="editSchoolContact($event)"/>
                    </b-tab>
                  </b-tabs>
                </div>
              </b-col>
            </b-row>

          <SchoolContact id="x" :schoolId="editSchoolId" @closeModal="modalClosed" :showModal="showEditModal" />

    </div>
</template>

<script>
import {eventBus} from "@/main"
import SchoolList from '@/components/SchoolList'
import SchoolContact from '@/components/SchoolContact'
import axios from "axios"
import axiosRetry from 'axios-retry'

axiosRetry(axios, { retries: 3,
    retryDelay: axiosRetry.exponentialDelay  }) 

export default {
  components: {
    SchoolList,
    SchoolContact
  },
  data: () => ({
    loading: true,
    isLoading: false,
    appTitle: 'Annual Immunization Status Report',
    showEditModal: false,
    editSchoolId: null
  }),
  
  computed: {
    userRole() {
      return this.$store.state.userRole
    },
    greeting() {
      return this.$keycloak.fullName
    },
  },

  mounted() {
  },

  methods: {
      showMsgBox() {
        this.$bvModal.msgBoxOk('Data was uploaded successfully. MDH will process the data and send you an email when complete.', {
          title: 'Confirmation',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
          .then(value => {
          })
          .catch(err => {
            // An error occurred
          })
      },

      getCurrentSchoolyear() {
          axios.get(`${process.env.VUE_APP_API_BASE_URL}/year/current`, { ignoreHeader: true })
          .then(response => { 
              this.appTitle = 'Annual Immunization Status Report (' + response.data.longLabel + ')'
          });
      },

      editSchoolContact(school) {
        this.editSchoolId = school.schoolId
        this.showEditModal = true
      },
      
      modalClosed() {
        this.editSchoolId = null
        this.showEditModal = false
        this.$nextTick(() => {      
          this.$bvModal.hide('modal-school-contact')
        })
    }
  },

  created() {
    eventBus.$on('upload-complete', (_employerId) => {
      this.showMsgBox()
    })
    this.getCurrentSchoolyear()
  }
}
</script>

<style>
 .green-header {
     color: #60A609;
 }
 .badge-success {
    color: #fff;
    background-color: #175E28;
}
.tab {  
  color: red  
}  
</style>