<template>
  <b-container fluid>

    <b-row class="mb-1"> 
      <b-col cols="12" class="mb-1">
        <h2>{{tabTitle}}</h2> 
      </b-col>

      <b-col cols="12">
        <div v-if="loading">
          <p>
            <b-alert show>
              <b-spinner small></b-spinner>
              Loading
            </b-alert>
          </p>
        </div>
        <b-table v-else hover small responsive show-empty
          :id="'list-' + schoolType"
          :fields="fields"
          :items="schools"
          primary-key="id">
            <template v-slot:cell(reportActions)="row">
              <span v-if="row.item.classification != '1'">
                <span v-if="row.item.reportId">
                  <b-button size="sm" @click="editReport(row.item)" class="mr-1">
                  edit report
                  </b-button><br/>
                  {{row.item.reportEditDate}}
                </span>
                <span v-else>
                  <b-button size="sm" @click="addReport(row.item)" class="mr-1">
                  add report
                  </b-button>
                </span>
              </span>
            </template>

            <template v-slot:cell(contactActions)="row">
                <b-button size="sm" @click="editContact(row.item)" class="mr-1">
                Edit Contact
                </b-button>
            </template>

            <template v-slot:cell(covidActions)="row">
                <b-button size="sm" @click="bulkQuery(row.item)" class="mr-1"
                  v-if="row.item.allowBulkQuery">
                Query MIIC
                </b-button>
            </template>
            

            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>

            <template v-slot:empty="scope">
                <p>No {{tabTitle}} found. Click the 'Request School Access' to add schools to your list.</p>
            </template>

            <div slot="table-busy" class="text-center text-danger my-2">
                <b-spinner class="align-middle" style="margin-right:5px; margin-top:12px;"></b-spinner>
                <strong>Loading, Please Wait...</strong>
            </div>  
        </b-table>
      </b-col>
    </b-row>



  </b-container>

</template>

<script>

import axios from 'axios'
import axiosRetry from 'axios-retry'
//import SchoolContact from '@/components/SchoolContact'

export default {

  data: () => ({
    loading: true,
    schools: [ ],
    fields: [ ],
    showEditModal: false,
    editSchoolId: null
  }),

  props: {
    schoolType: String
  },

  components: {
   // SchoolContact
  },

  mounted() {
    this.loadSchools()
    this.defineColumns()
  },
  
  computed: {
    tabTitle() {
      switch(this.schoolType) {
        case 'public': return 'Public/Charter Schools'
        case 'private': return 'Private Schools'
        case 'ccc': return 'Child Care Centers'
        case 'hied': return 'Insitutes of Higher Education'
        default: return 'Unknown'
      }
    }
  },

  methods: {
    loadSchools() {
      this.loading = true 
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/school/list/${this.schoolType}`)
          .then(response => { 
              this.schools = response.data
              this.loading = false
          });
    },

    defineColumns() {
      if (this.schoolType != 'hied') {
       this.fields.push({key: 'reportActions', label: 'Last Updated Date'})
      }
      if (this.schoolType === 'public' || this.schoolType === 'private') {
        this.fields.push({key: 'districtId', label: 'District ID'})
        this.fields.push({key: 'districtName', label: 'District Name'})
      }
      this.fields.push({key: 'name', label: 'School Name'})
      this.fields.push({key: 'schoolTypeLabel', label: 'Type'})
      this.fields.push({key: 'address.city', label: 'City'})
      this.fields.push({key: 'county', label: 'County'})
      this.fields.push({key: 'contactActions', label: 'Contact'})
      this.fields.push({key: 'covidActions', label: 'Bulk Query'})
    },

    editContact(school) {
      this.editSchoolId = school.id 
      this.showEditModal = true
      this.$emit('edit-school-contact', {schoolId: school.id})
    },

    editReport(school) {
       this.$router.push({ name: 'report-rates', params: { reportId: school.reportId } })
    },

    addReport(school) {
       this.$router.push({ name: 'report', params: { schoolId: school.id } })
    },

    bulkQuery(school) {
      this.$router.push({ name: 'bulk', params: { schoolId: school.id } })
    },

    modalClosed() {
      this.showEditModal = false
    }

  },

}
</script>

<style>
</style>
